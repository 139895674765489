@font-face {
    font-family: FuturaTrue;
    src: url(./FuturaStdMedium.ttf);
}

@font-face {
    font-family: FuturaBook;
    src: url(./FuturaBook.ttf);
}

@font-face {
    font-family: FuturaBook2;
    src: url(./FuturaStdBook.ttf);
}