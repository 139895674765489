@use '@angular/material' as mat;
$custom-typography: mat.define-typography-config(
    $font-family : 'FuturaBook2'
);
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($custom-typography);

@import "~@ng-select/ng-select/themes/material.theme.css";
@import './assets/fonts/commonFont.css';

$mat-black: ( 
    50: #000000, 
    100: #000000, 
    200: #000000, 
    300: #000000, 
    400: #000000, 
    500: #000000, 
    600: #000000, 
    700: #000000, 
    800: #000000, 
    900: #000000, 
    A100: #ffffff, 
    A200: #eeeeee, 
    A400: #bdbdbd, 
    A700: #616161, 
    contrast: ( 
        50: #ffffff, 
        100: #ffffff, 
        200: #ffffff, 
        300: #ffffff, 
        400: #ffffff, 
        500: #ffffff, 
        600: #ffffff, 
        700: #ffffff, 
        800: #ffffff, 
        900: #ffffff, 
        A100: #ffffff, 
        A200: #ffffff, 
        A400: #ffffff, 
        A700: #ffffff, 
    )
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$canvia-app-primary: mat.define-palette($mat-black);
$canvia-app-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$canvia-app-warn:  mat.define-palette(mat.$red-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$canvia-app-theme: mat.define-light-theme((
    color: (
      primary: $canvia-app-primary,
      accent: $canvia-app-accent,
      warn: $canvia-app-warn,
    )
));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($canvia-app-theme);
//css
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: FuturaTrue, "Helvetica", "Arial", sans-serif !important;
}

.btn {
    border-radius: 0px!important;
    text-transform: uppercase;
}

footer {
    display: block;
    background: #fff;
    padding: 30px 0;
}

footer p.copyright-text {
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    color: #909090;
}

.social-footer {
    list-style-type: none;
    display: block;
    font-size: 0px;
    text-align: center;
    margin: 0;
    padding: 0;
}

.social-footer li {
    display: inline-block;
    margin: 0 8px;
}

.card:hover .card-description {
    font-weight: 300;
}


/* in-flight clone */

.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
}


/* high-performance display:none; helper */

.gu-hide {
    left: -9999px !important;
}


/* added to mirrorContainer (default = body) while dragging */

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}


/* added to the source element while its mirror is dragged */

.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

.text-block-premium {
    text-transform: capitalize;
    position: absolute;
    bottom: -2px;
    right: 0px;
    border-radius: 1.5rem;
}

.modal-open .modal {
    overflow-x: auto!important;
    overflow-y: auto;
}

app-artist-modal,
app-register-form,
app-home,
app-reset,
app-login-form,
app-after-reset {
    .carousel-indicators li {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: none;
        border: 2px solid white;
    }
    .carousel-item {
        padding-top: 0%;
    }
}

app-allplaylists,
app-allartworks,
app-allartists,
app-myartworks {
    .mat-select-arrow {
        color: black;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid;
        margin: 0 4px;
    }
    mat-option {
        font-size: inherit;
        line-height: 3em;
        height: 2.5em;
        border: 1px solid;
        text-transform: uppercase;
    }
    mat-select-panel {
        box-shadow: none!important;
    }
    mat-icon.mat-chip-remove.mat-chip-trailing-icon.mat-icon.material-icons {
        color: white;
        opacity: 1;
    }
    mat-chip {
        border-radius: 0px!important;
    }
    a.pull-right {
        color: white!important;
        background: black!important;
    }
}

ul.breadcrumb {
    text-transform: uppercase;
    li {
        a {
            color: black;
            font-size: 18px;
            font-family: 'FuturaBook';
        }
    }
}

body::-webkit-scrollbar {
    width: 2px;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: black;
    outline: 1px solid black;
}

app-playlist-bar {
    .mat-menu-content {
        padding: 0px!important;
    }
    .mat-select-value {
        text-transform: none!important;
    }
    .queue-header {
        .mat-menu-content:not(:empty) {
            padding-top: 0px;
            padding-bottom: 0px;
        }
        .mat-menu-item {
            font-size: 1rem;
            font-weight: 500 !important;
            // text-transform: uppercase;
            font-family: futurabook2;
            border: 1px solid;
        }
    }
}
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';


.app-alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid transparent;
    border-radius: .25rem;
    padding: .75rem 1.25rem;
};

.signin-button {
    width: 210px;
    height: 40px;
}